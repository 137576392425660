export const environment = {
    production: false,
    firebase: {
      apiKey: "xxxxxxxx-xxxxxxxx",
      authDomain: "xxxxxxxxxxxxxxxxxxxxxxxx",
      projectId: "xxxxxxxx",
      storageBucket: "xxxxxxxx",
      messagingSenderId: "xxxxxx",
      appId: "xxxxx",
      SITE_KEY_HEADER_PARAMETER_NAME: 'Brain-Coach-Development-Api-Key',
      SITE_KEY_VALUE:'VcFbbAWN.E2RvKgHQg9Tnxj69HgsggC1vavrjZcdU',
      rootUrl: 'https://demo.api.braincoach.ai/v1',
      apiKeyStorage: '',
      apiKeyLoginStorage: ''
      
    }
  };